import React, { useEffect, useState, useRef } from 'react';
import socketIOClient from 'socket.io-client';
import './Dashboard.css'; // Import the CSS file
import { ReactComponent as ChatTradeLogo } from './ChatTradeLogo.svg'; // Import the SVG file
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const ENDPOINT = "https://ctrade.beducci.com";
// const ENDPOINT = "http://localhost:3003";

const Dashboard = () => {
  const [status, setStatus] = useState({});
  const [positions, setPositions] = useState([]);
  const [backtestResults, setBacktestResults] = useState({});
  const [accountInfo, setAccountInfo] = useState({});
  const [combinedData, setCombinedData] = useState({});
  const tableRef = useRef(null);

  useEffect(() => {
    const socket = socketIOClient(ENDPOINT);

    // Request position update when the component mounts
    socket.emit('requestPositionUpdate');

    socket.on('positionUpdate', (data) => {
      setPositions(data.positions);
    });

    socket.emit('requestAccountInfo');

    socket.on('accountBalanceUpdate', (data) => {
      setAccountInfo(data);
    });

    socket.on('statusUpdate', (data) => {
      const newStatus = {};
      Object.keys(data.collectedData).forEach(asset => {
        const lastPeriod = data.collectedData[asset][data.collectedData[asset].length - 1];
        newStatus[asset] = lastPeriod;
      });
      setStatus((prevStatus) => {
        return {
          ...prevStatus,
          ...newStatus
        };
      });
      if (data.account) {
        setAccountInfo(data.account);
      }
    });

    return () => socket.disconnect();
  }, []);

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList' || mutation.type === 'characterData') {
          const target = mutation.target.parentNode;
          if (target.classList) {
            target.classList.add('updated');
            setTimeout(() => {
              target.classList.remove('updated');
            }, 1000); // Remove the class after the animation duration
          }
        }
      });
    });

    if (tableRef.current) {
      observer.observe(tableRef.current, {
        childList: true,
        subtree: true,
        characterData: true
      });
    }

    return () => observer.disconnect();
  }, [positions, backtestResults, accountInfo]);

  useEffect(() => {
    // Combine status and positions data
    const combined = {};
    Object.keys(status).forEach(asset => {
      combined[asset] = {
        ...status[asset],
        positions: positions.filter(pos => pos.market.epic === asset) || []
      };
    });
    setCombinedData(combined);
  }, [status, positions]);

  const runBacktest = (asset) => {
    console.log(`Running backtest for ${asset}`);
    // Add your backtest logic here
    // You can make an API call to the server to run the backtest
    fetch(`/api/runBacktest?asset=${asset}`)
      .then(response => response.json())
      .then(data => {
        console.log(`Backtest results for ${asset}:`, data);
        if (data && data.data && data.data.profitLoss !== undefined) {
          setBacktestResults(prevResults => ({
            ...prevResults,
            [asset]: data.data.profitLoss
          }));
        } else {
          console.error('Invalid backtest response:', data);
        }
      })
      .catch(error => {
        console.error('Error running backtest:', error);
      });
  };

  const renderTable = (asset, index) => {
    const data = combinedData[asset];
    const contract = data.positions;
    const profitLoss = backtestResults[asset];
    var buttonClass = profitLoss > 0 ? 'button positive' : 'button negative';
    if (profitLoss === undefined) {
      buttonClass = 'button';
    }

    return (
      <tr key={asset} className="data-row" onClick={() => window.location.href = `/graph/${asset}`} style={{ cursor: 'pointer' }}>
        <td className="data-cell data-cell-bold">{index + 1}. {asset}</td>
        <td className="data-cell">{data.open?.toFixed(5) || 'N/A'}</td>
        <td className="data-cell">{data.close?.toFixed(5) || 'N/A'}</td>
        <td className="data-cell">{contract.map((o, idx) => <p key={idx}>{o.position.size}</p>)}</td>
        <td className="data-cell">{contract.map((o, idx) => <p key={idx}>{o.position.stopLevel}</p>)}</td>
        <td className="data-cell">{contract.map((o, idx) => <p key={idx}>{o.position.profitLevel}</p>)}</td>
        <td className="data-cell">{contract.map((o, idx) => <p key={idx} className={o.position.upl >= 0 ? 'positive' : 'negative'}>{o.position.upl}</p>)}</td>
        <td className="data-cell">{contract.map((o, idx) => <p key={idx}>{o.position.leverage}</p>)}</td>
        <td className="data-cell market-status">
          {contract[0]?.market?.marketStatus}
          {
            contract[0] ? <span style={{ top: '1px' }} className={`dot ${contract[0]?.market?.marketStatus === 'TRADEABLE' ? 'open' : 'closed'}`}></span> : ''
          }
        </td>
        <td className="data-cell data-cell-actions">
          <button className={buttonClass} onClick={(e) => { e.stopPropagation(); runBacktest(asset); }}>
            {profitLoss !== undefined ? `P/L: ${profitLoss.toFixed(2)}` : 'Run Backtest'}
          </button>
        </td>
      </tr>
    )
  };

  // Separate positions with and without positionId
  const positionsWithContracts = Object.keys(combinedData).filter(asset => combinedData[asset].positions.length > 0).sort();
  const positionsWithoutContracts = Object.keys(combinedData).filter(asset => combinedData[asset].positions.length === 0).sort();

  // Concatenate the sorted groups, with positions having positionId on top
  const sortedPositions = [...positionsWithContracts, ...positionsWithoutContracts];

  // Function to determine the CSS class based on the value
  const getAmountClass = (amount) => {
    return amount < 0 ? 'negative' : 'positive';
  };

  return (
    <div className="dashboard">
      <div className="title">
        <Link to="/">
          <ChatTradeLogo />
        </Link>
        <div className="account-info">
          <span>Balance: {accountInfo.currencySymbol} <span className={getAmountClass(accountInfo.balance)}>{accountInfo.balance?.toFixed(2)}</span></span>
          <span>Available: {accountInfo.currencySymbol} <span className={getAmountClass(accountInfo.available)}>{accountInfo.available?.toFixed(2)}</span></span>
          <span>Profit/Loss: {accountInfo.currencySymbol} <span className={getAmountClass(accountInfo.profitLoss)}>{accountInfo.profitLoss?.toFixed(2)}</span></span>
        </div>
      </div>

      <table className="styled-table" ref={tableRef}>
        <thead>
          <tr>
            <th className="header-cell">Asset</th>
            <th className="header-cell">Open</th>
            <th className="header-cell">Close</th>
            <th className="header-cell">Size</th>
            <th className="header-cell">Stop Loss</th>
            <th className="header-cell">Take Profit</th>
            <th className="header-cell">P&L</th>
            <th className="header-cell">Leverage</th>
            <th className="header-cell">Market Status</th>
            <th className="header-cell" style={{ width: '120px' }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {sortedPositions.map((asset, index) => {
            return renderTable(asset, index)
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Dashboard;