import React, { useEffect, useRef, useState } from 'react';
import { useParams, Link } from 'react-router-dom'; // Import Link
import socketIOClient from 'socket.io-client';
import { ReactComponent as ChatTradeLogo } from './ChatTradeLogo.svg'; // Import the SVG file
import './Graph.css';

const ENDPOINT = "https://ctrade.beducci.com";
// const ENDPOINT = "http://localhost:3003";

function Graph() {
  const { symbol } = useParams();
  const containerRef = useRef(null);
  const widgetRef = useRef(null);
  const socketRef = useRef(null);
  const [accountInfo, setAccountInfo] = useState({});
  const [initialData, setInitialData] = useState(null);
  const [positions, setPositions] = useState([]);
  const [combinedData, setCombinedData] = useState({});

  const initializeChart = () => {
    console.log('Initializing chart');
    const loadTradingViewLibrary = () => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = '/TV/charting_library/charting_library.js';
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    };

    loadTradingViewLibrary()
      .then(() => {
        if (window.TradingView && containerRef.current) {
          try {
            console.log(containerRef.current);
            const tvWidget = new window.TradingView.widget({
              symbol: symbol || 'BTCUSD', // Provide a default symbol
              datafeed: createDatafeed(),
              container: containerRef.current,
              library_path: '/TV/charting_library/',
              locale: 'en',
              enabled_features: ['seconds_resolution'],
              disabled_features: ['header_symbol_search', 'header_compare'],
              overrides: {
                'mainSeriesProperties.sessionId': 'extended'
              },
              fullscreen: true,
              autosize: true,
              timezone: 'Europe/Stockholm',
              theme: 'Dark',
              debug: true, // Enable debug mode
              onSeriesError: (error) => {
                console.log(error);
                console.error('Series error:', error);
              },
            });

            tvWidget.onChartReady(() => {
              // tvWidget.activeChart().createStudy('MACD', false, false, {
              //   fastLength: 12,
              //   slowLength: 26,
              //   signalLength: 9,
              //   source: "close"
              // });
              tvWidget.activeChart().createStudy('Relative Strength Index', false, false, {
                length: 14,
                source: "close"
              }, {
                'upperLimit.color': 'red',
                'upperLimit.value': 75,
                'lowerLimit.color': 'green',
                'lowerLimit.value': 25,
              });

              // Find position where asset matches symbol
              const matchingPosition = positions.find(position => position.asset === symbol);

              if (matchingPosition) {
                const openLevel = matchingPosition.openLevel;
                const takeProfit = matchingPosition.takeProfit;
                tvWidget.activeChart().createMultipointShape([
                  { time: new Date(matchingPosition.createdAt).getTime() / 1000, price: openLevel },
                  { time: tvWidget.activeChart().getVisibleRange().to, price: takeProfit }
                ], {
                  shape: 'fib_retracement',
                  overrides: {
                    linecolor: '#00FFFF',
                    transparency: 50,
                    'level1.color': '#FF0000',
                    'level2.color': '#00FF00',
                    'level3.color': '#0000FF',
                    'level4.color': '#FFFF00',
                    'level5.color': '#FF00FF',
                    'level6.color': '#00FFFF',
                    'level7.color': '#FFFFFF'
                  }
                });

                // Create take profit line
                const takeProfitLine = tvWidget.activeChart().createOrderLine();
                takeProfitLine.setText('Take Profit');
                takeProfitLine.setPrice(matchingPosition.takeProfit);
                takeProfitLine.setLineColor('#00FF00'); // Green color for take profit

                // Create dynamic take profit line based on Fibonacci retracement
                const calculateDynamicTakeProfit = () => {
                  const chart = tvWidget.activeChart();
                  const openTime = new Date(matchingPosition.createdAt).getTime() / 1000;
                  const currentTime = chart.getVisibleRange().to;
                  const openLevel = matchingPosition.openLevel;
                  const periods = chart.getVisiblePriceRange(openTime, currentTime);
                  const prices = periods
                    .filter(period => period.close >= openLevel)
                    .map(period => period.close);

                  //     console.log(prices);
                  // const highPrice = prices.high;
                  // const lowPrice = prices.low;
                  // const currentPrice = chart.getLastPrice();

                  // const fibLevels = [0, 0.236, 0.382, 0.5, 0.618, 0.786, 1];
                  // const priceRange = highPrice - lowPrice;

                  // let currentZone = 0;
                  // let nextLowerZone = 0;

                  // for (let i = fibLevels.length - 1; i >= 0; i--) {
                  //     const fibPrice = lowPrice + (priceRange * fibLevels[i]);
                  //     if (currentPrice >= fibPrice) {
                  //         currentZone = i;
                  //         nextLowerZone = Math.max(0, i - 1);
                  //         break;
                  //     }
                  // }

                  // const currentZonePrice = lowPrice + (priceRange * fibLevels[currentZone]);
                  // const nextLowerZonePrice = lowPrice + (priceRange * fibLevels[nextLowerZone]);

                  // if (currentPrice < currentZonePrice && currentPrice > nextLowerZonePrice) {
                  //     return nextLowerZonePrice;
                  // }

                  return null; // No dynamic take profit if price is not moving downwards between zones
                };

                // console.log(calculateDynamicTakeProfit());
                // calculateDynamicTakeProfit()
                // const dynamicTakeProfitLine = tvWidget.activeChart().createOrderLine();
                // dynamicTakeProfitLine.setText('Dynamic Take Profit');
                // dynamicTakeProfitLine.setPrice(calculateDynamicTakeProfit());
                // dynamicTakeProfitLine.setLineColor('#FFA500'); // Orange color for dynamic take profit

                // Update dynamic take profit line on price scale changed
                // tvWidget.activeChart().onPriceScaleChanged().subscribe(null, () => {
                //     dynamicTakeProfitLine.setPrice(calculateDynamicTakeProfit());
                // });

                // Create open level line
                const openLevelLine = tvWidget.activeChart().createOrderLine();
                openLevelLine.setText('Open Level');
                openLevelLine.setPrice(matchingPosition.openLevel);
                openLevelLine.setLineColor('#0000FF'); // Blue color for open level

                // Create vertical line for order creation time
                tvWidget.activeChart().createShape({
                  time: new Date(matchingPosition.createdAt).getTime() / 1000,
                  price: matchingPosition.openLevel
                }, {
                  shape: 'vertical_line',
                  text: 'Order Created',
                  lock: true,
                  disableSelection: true,
                  color: '#FFFFFF', // White color for the vertical line
                  textColor: '#FFFFFF',
                  fontsize: 12,
                  zIndex: 5,
                  timezone: 'Europe/Stockholm'
                });

                // Create stop loss line
                const stopLossLine = tvWidget.activeChart().createOrderLine();
                stopLossLine.setText('Stop Loss');
                stopLossLine.setPrice(matchingPosition.stopLoss);
                stopLossLine.setLineColor('#FF0000'); // Red color for stop loss

                console.log(`Created lines for ${symbol}: Take Profit at ${matchingPosition.takeProfit}, Stop Loss at ${matchingPosition.stopLoss}`);
              } else {
                console.log(`No matching position found for ${symbol}`);
              }
            });

            widgetRef.current = tvWidget;
          } catch (error) {
            console.error('Error initializing TradingView widget:', error);
          }
        } else {
          console.error('TradingView library not loaded');
        }
      })
      .catch((error) => {
        console.error('Error loading TradingView library:', error);
      });
  };

  const createDatafeed = () => {
    return {
      onReady: (callback) => {
        setTimeout(() => callback({
          supported_resolutions: ['5']
        }));
      },
      searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {
        // Implement symbol search if needed
      },
      resolveSymbol: (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
        console.log('Resolving symbol:', symbolName);
        try {
          onSymbolResolvedCallback({
            name: symbolName,
            full_name: symbolName,
            description: symbolName,
            display_market_status: true,
            multiple_watchlists: true,
            type: 'forex',
            // session: '24x7',
            exchange: 'CAPITALCOM',
            timezone: 'Europe/Stockholm',
            ticker: symbolName,
            minmov: 1,
            pricescale: 100000,
            has_intraday: true,
            intraday_multipliers: ['1', '5', '15', '30', '60'],
            supported_resolutions: ['5'],
            volume_precision: 3,
            data_status: 'streaming',
          });
        } catch (error) {
          console.error('Error resolving symbol:', error);
          onResolveErrorCallback('Error resolving symbol');
        }
      },
      getBars: (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
        const { from, to } = periodParams
        if (initialData) {
          let bars = []
          initialData.forEach((bar) => {
            if (bar.date >= from && bar.date < to) {
              bars = [
                ...bars,
                {
                  time: bar.date * 1000,
                  low: bar.low,
                  high: bar.high,
                  open: bar.open,
                  close: bar.close
                }
              ]
            }
          })

          // Use the initial data for the first set of bars
          onHistoryCallback(bars, { noData: false });
        } else {
          // Implement historical data fetching here if needed
          onHistoryCallback([], { noData: true });
        }
      },
      subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) => {
        console.log(`Subscribing to ${symbolInfo.name}`);

        const socket = socketIOClient(ENDPOINT);
        socket.on('statusUpdate', (data) => {
          const bars = data.collectedData[symbol];
          if (bars && Array.isArray(bars)) {
            const lastBar = bars[bars.length - 1];
            lastBar.time = lastBar.date * 1000;
            onRealtimeCallback(lastBar);
          } else {
            console.error('Invalid or missing bar data');
          }
        });

        socket.on('accountBalanceUpdate', (data) => {
          setAccountInfo(data);
        });

        return () => {
          console.log(`Unsubscribing from ${symbolInfo.name}`);
          socket.disconnect();
        };
      },
      unsubscribeBars: (subscriberUID) => {
        console.log(`Unsubscribing: ${subscriberUID}`);
        if (socketRef.current) {
          socketRef.current.disconnect();
        }
      },
    };
  };

  const fetchInitialData = () => {
    const socket = socketIOClient(ENDPOINT);
    socket.on('statusUpdate', (data) => {
      if (data.account) {
        setAccountInfo(data.account);
      }
      setInitialData(data.collectedData[symbol]);
      setPositions(data.positions);
      socket.disconnect();
    });

    socket.emit('requestAccountInfo');

    socket.on('accountBalanceUpdate', (data) => {
      setAccountInfo(data);
    });

    socket.on('statusUpdate', (data) => {
      const { positions, collectedData } = data;
      // Combine status and positions data
      const combined = {};
      Object.keys(collectedData).forEach(asset => {
        combined[asset] = {
          positions: positions.filter(pos => pos.asset === asset) || []
        };
      });
      setCombinedData(combined);
      // const newStatus = {};
      // Object.keys(data.collectedData).forEach(asset => {
      //   const lastPeriod = data.collectedData[asset][data.collectedData[asset].length - 1];
      //   newStatus[asset] = lastPeriod;
      // });
      // setStatus((prevStatus) => {
      //   return {
      //     ...prevStatus,
      //     ...newStatus
      //   };
      // });
    });
  };

  // Function to determine the CSS class based on the value
  const getAmountClass = (amount) => {
    return amount < 0 ? 'negative' : 'positive';
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  useEffect(() => {
    initializeChart();
  }, [initialData]);

  // Separate positions with and without positionId
  const positionsWithContracts = Object.keys(combinedData).filter(asset => combinedData[asset].positions.length > 0).sort();
  const positionsWithoutContracts = Object.keys(combinedData).filter(asset => combinedData[asset].positions.length === 0).sort();

  // Concatenate the sorted groups, with positions having positionId on top
  const sortedPositions = [...positionsWithContracts, ...positionsWithoutContracts];

  return (
    <div className="graph">
      <div className="title">
        <Link to="/">
          <ChatTradeLogo />
        </Link>
        <div className="account-info">
          <span>Balance: {accountInfo.currencySymbol} <span className={getAmountClass(accountInfo.balance)}>{accountInfo.balance?.toFixed(2)}</span></span>
          <span>Available: {accountInfo.currencySymbol} <span className={getAmountClass(accountInfo.available)}>{accountInfo.available?.toFixed(2)}</span></span>
          <span>Profit/Loss: {accountInfo.currencySymbol} <span className={getAmountClass(accountInfo.profitLoss)}>{accountInfo.profitLoss?.toFixed(2)}</span></span>
        </div>
      </div>

      <table className="styled-table" style={{ flex: '1 1 100%' }}>
        <thead>
          <tr>
            <th className="header-cell">Asset</th>
          </tr>
        </thead>
        <tbody>
          {(sortedPositions).map((asset, index) => {
            const getPosition = positions && positions.length > 0 ? positions.find(position => position.asset === asset) : null;
            console.log(getPosition);
            return (
              <tr className="data-row" key={index}>
                <td className={`data-cell market-status data-cell-bold ${asset === symbol ? 'highlighted' : ''}`}>
                  <a href={`/graph/${asset}`}>{index + 1}. {asset}
                    {
                      getPosition && (
                        <span>
                          <span className={`${getPosition.profitAndLoss > 0 ? 'positive' : 'negative'}`}>{getPosition.profitAndLoss}</span>
                          <span className="dot open"></span>
                        </span>
                      )
                    }
                  </a>
                  {
                    getPosition && getPosition.rsi && (
                      <div className='indicator'>
                        <div>
                          <span>RSI: </span>
                          <span>{getPosition.rsi}</span>
                        </div>
                        <div>
                          <span>MACD: </span>
                          <span>{getPosition.macd}</span>
                        </div>
                      </div>
                    )
                  }
                </td>
                {index === 0 ? <td className='chart-cell' rowSpan={sortedPositions.length} ref={containerRef} /> : ''}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  );
}

export default Graph;